<template>
    <div class="row pb-5">
        <div class="col-12">
            <div class="card rounded-lg bg-white border-light border p-5">
                <h2 class="text-center display-1 fw-700 mb-4">Free Coupon</h2>
                <p class="text-center font-xsss fw-500 lh-28 text-grey-500 pl-0">Do you have a coupon code from a <b class="fw-700 text-grey-700">Courses</b> purchases or <b class="fw-700 text-grey-700">scholarship program</b>? Let's use the coupon to get many privileges and adventages. Enter the coupon code in field below</p>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group border-light border p-2 shadow bg-white rounded-lg">
                            <div class="row">
                                <div class="col-lg-9">
                                    <div class="form-group icon-input mb-0">
                                        <input type="text" v-model="code" placeholder="Enter coupon code" class="style1-input border-0 pl-4 font-xsss mb-0 text-grey-800 fw-500 bg-transparent" style="width: 100%;" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-3 mb-5">
                    <div class="col-12 text-center">
                        <button @click="inputCoupon" class="btn btn-current btn-lg fw-500" :class="{'disabled': load}" :disabled="load">{{ load ? 'Process..' : 'Submit'}}</button>
                    </div>
                </div>
                <div class="row" v-if="course">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-6 mb-4">
                                <div class="product-slider-4">
                                <img class=" mx-auto d-block" style="width: 85%;" :src="course.banner ?  course.banner : 'https://via.placeholder.com/500.png'">
                                </div> 
                            </div>
                            <div class="col-lg-5 ml-1 offset-lg-1 col-md-12 pad-top-lg-200 pad-bottom-lg-100 pad-top-100 pad-bottom-75 pl-md--5">
                                <h4 class="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-warning mr-1">{{ course.category }} - {{ course.level }}</h4>
                                <h3 class="fw-700 text-grey-900 display1-size lh-3 porduct-title display2-md-size display1-sm-size mt-1"> {{course.name}}</h3>
                                <div class="clearfix"></div>
                                <p class="font-xsss fw-600 text-grey-600 lh-30 pr-lg--5 mt-2 mr-5 ml-1">Instructor : {{course.created_by_name}}</p>
                                <div class="row my-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-signal mr-2"></i>{{course.category}} {{course.level}}</div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-copy mr-2"></i>{{course.total_material}} Materials</div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-puzzle-piece mr-2"></i>{{course.total_assignment}} Assignments</div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-user-graduate mr-2"></i>{{course.total_student}} Students</div>
                                </div>
                                <!-- <h6 class="font-xxl fw-700 text-current ls-2 mb-1">Rp.{{ course.price | numFormat('0,0') }}</h6> -->
                                <div class="row" v-if="course.coupon">
                                    <div class="col-12">
                                        <table class="table table-bordered border-white text-center">
                                            <thead class="bg-current text-black">
                                                <tr>
                                                    <th>Cycle</th>
                                                    <th>Regular</th>
                                                    <th>Special</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="fw-600">Cycle 1</td>
                                                    <td>
                                                        <button class="btn btn-sm" :class="{'btn-success': course.coupon.cycle_1, 'btn-danger': !course.coupon.cycle_1}">
                                                            <i class="fas" :class="{'fa-check': course.coupon.cycle_1, 'fa-times': !course.coupon.cycle_1}"></i>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-sm" :class="{'btn-success': course.coupon.cycle_1_special, 'btn-danger': !course.coupon.cycle_1_special}">
                                                            <i class="fas" :class="{'fa-check': course.coupon.cycle_1_special, 'fa-times': !course.coupon.cycle_1_special}"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-600">Cycle 2</td>
                                                    <td>
                                                        <button class="btn btn-sm" :class="{'btn-success': course.coupon.cycle_2, 'btn-danger': !course.coupon.cycle_2}">
                                                            <i class="fas" :class="{'fa-check': course.coupon.cycle_2, 'fa-times': !course.coupon.cycle_2}"></i>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-sm" :class="{'btn-success': course.coupon.cycle_2_special, 'btn-danger': !course.coupon.cycle_2_special}">
                                                            <i class="fas" :class="{'fa-check': course.coupon.cycle_2_special, 'fa-times': !course.coupon.cycle_2_special}"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-600">Cycle 3</td>
                                                    <td>
                                                        <button class="btn btn-sm" :class="{'btn-success': course.coupon.cycle_3, 'btn-danger': !course.coupon.cycle_3}">
                                                            <i class="fas" :class="{'fa-check': course.coupon.cycle_3, 'fa-times': !course.coupon.cycle_3}"></i>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-sm" :class="{'btn-success': course.coupon.cycle_3_special, 'btn-danger': !course.coupon.cycle_3_special}">
                                                            <i class="fas" :class="{'fa-check': course.coupon.cycle_3_special, 'fa-times': !course.coupon.cycle_3_special}"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div action="#" class="form-action my-4">
                                    <div class="product-action flex-row align-items-center">
                                        <button @click="submitCoupon()" class="w-100 border-0 add-to-cart bg-current text-white pl-lg-5 pr-lg-5 fw-700 text-uppercase font-xssss float-left rounded-lg border-size-md d-inline-block mt-0 p-3 text-center ls-3">{{!course.owned ? 'Get Now!' : 'Upgrade!' }}</button>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            media: process.env.VUE_APP_URL_CLOUD,
            code: '',
            load: false,
            course: null,
        }
    },
    methods:{
        async inputCoupon() {
            this.load = true
            this.course = null
            await axios.get(`${process.env.VUE_APP_URL_API}/core/coupon?code=${this.code}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                if(res.data.success) {
                    this.course = res.data.data
                }
            }).catch((err) => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Course Coupon',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else if (err.response.status == 400){
                    this.$swal({
                        toast: true,
                        title: 'Course Coupon',
                        text: err.response.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).finally(() => this.load = false)
        },
        async submitCoupon() {
            var data = {
                code: this.code
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/core/coupon`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Success Input Coupon !',
                    text: res.data,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'MyCourseDashboard', params:{idCourse: this.course.slug} })
            }).catch((err) => {
                if (err.response.status == 422){
                    this.validationErrors = Object.values(err.response.data.errors);
                    this.$swal({
                        toast: true,
                        title: 'Course Coupon',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                else if (err.response.status == 400){
                    this.$swal({
                        toast: true,
                        title: 'Course Coupon',
                        text: err.response.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>